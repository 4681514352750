import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCartData } from "../services/cartService";
import { getAllPantryListWithoutPagination } from "../services/pantryService";

const Context = createContext("");

export function RoleStore(props) {
  const user = useSelector((state) => state?.auth?.user);
  const [cartCount, setCartCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pantryLists, setPantryLists] = useState([]);
  const [reloadCart, setReloadCart] = useState(false);

  const [cartToken, setCartToken] = useState("");

  const getCartCount = async () => {
    try {
      const raw = {
        userId: user?.id,
        customerCode: user?.customerCode,
      };
      const resp = await getCartData(raw);
      if (resp?.success)
        setCartCount(resp?.data?.length ? resp.data.length : 0);
      const total = resp?.data?.reduce((acc, item) => {
        const price = item?.userCartDetails?.uomMultiplier
          ? item?.userCartDetails?.price * item?.userCartDetails?.uomMultiplier
          : item?.userCartDetails?.price ?? 0;
        // const price = item?.userCartDetails?.price ?? 0;
        const quantity = item?.quantity ?? 1;
        return acc + price * quantity;
      }, 0);

      setTotalPrice(total.toFixed(2));
    } catch (err) {
      console.error("Failed to fetch cart data", err);
    }
  };

  const fetchAllPantryListByUserId = async () => {
    if (!user?.id) return;
    try {
      const resp = await getAllPantryListWithoutPagination(user?.customerCode, {
        search: "",
      });
      if (resp?.success) setPantryLists(resp?.data);
    } catch (err) {
      console.error("Error fetching lists for user:", err);
    }
  };

  useEffect(() => {
    if (user?.id) fetchAllPantryListByUserId(); // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (user?.id) getCartCount(); // eslint-disable-next-line
  }, []);

  return (
    <Context.Provider
      value={{
        cartCount,
        totalPrice,
        pantryLists,
        reloadCart,
        cartToken,
        ...{
          getCartCount,
          fetchAllPantryListByUserId,
          setReloadCart,
          setCartToken,
        },
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default Context;
