import React from "react";

const Password = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 2.6001H7.4C4.38301 2.6001 2.87452 2.6001 1.93726 3.53735C1 4.47462 1 5.98311 1 9.0001C1 12.0171 1 13.5256 1.93726 14.4628C2.87452 15.4001 4.38301 15.4001 7.4 15.4001H9M11.4 2.60084C13.8914 2.60813 15.2122 2.68688 16.0627 3.53735C17 4.47462 17 5.98311 17 9.0001C17 12.0171 17 13.5256 16.0627 14.4628C15.2122 15.3133 13.8914 15.3921 11.4 15.3994"
          stroke="#899CBB"
          strokeWidth="1.32"
          strokeLinecap="round"
        />
        <path
          d="M6.6 8.99995C6.6 9.44179 6.24182 9.79995 5.8 9.79995C5.35818 9.79995 5 9.44179 5 8.99995C5 8.55811 5.35818 8.19995 5.8 8.19995C6.24182 8.19995 6.6 8.55811 6.6 8.99995Z"
          fill="#899CBB"
        />
        <path
          d="M9.8002 8.99995C9.8002 9.44179 9.44204 9.79995 9.0002 9.79995C8.55836 9.79995 8.2002 9.44179 8.2002 8.99995C8.2002 8.55811 8.55836 8.19995 9.0002 8.19995C9.44204 8.19995 9.8002 8.55811 9.8002 8.99995Z"
          fill="#899CBB"
        />
        <path
          d="M11.4004 1V17"
          stroke="#899CBB"
          strokeWidth="1.32"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default Password;
