import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  selectedSubcategoryCodes: [],
  selectedLeafCategoryCodes: [],
};

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setSelectedSubcategoryCodes: (state, action) => {
      state.selectedSubcategoryCodes = [
        ...new Set([...state.selectedSubcategoryCodes, ...action.payload]),
      ];
    },
    // setSelectedLeafCategoryCodes: (state, action) => {
    //   state.selectedLeafCategoryCodes = [
    //     ...new Set([...state.selectedLeafCategoryCodes, action.payload]),
    //   ];
    // },
    setSelectedLeafCategoryCodes: (state, action) => {
      if (state.selectedLeafCategoryCodes.includes(action.payload)) {
        // If item exists, remove it
        state.selectedLeafCategoryCodes =
          state.selectedLeafCategoryCodes.filter(
            (code) => code !== action.payload
          );
      } else {
        // Otherwise, add it
        state.selectedLeafCategoryCodes = [
          ...state.selectedLeafCategoryCodes,
          action.payload,
        ];
      }
    },

    setSelectedLeafCategoryCodesHome: (state, action) => {
      state.selectedLeafCategoryCodes = [
        ...new Set([...state.selectedLeafCategoryCodes, ...action.payload]),
      ];
    },

    setSelectedLeafCategoryCodesByClickingMain: (state, action) => {
      state.selectedLeafCategoryCodes = [
        ...new Set([...state.selectedLeafCategoryCodes, ...action.payload]),
      ];
    },
    resetSelectedCategories: (state) => {
      state.selectedSubcategoryCodes = [];
      state.selectedLeafCategoryCodes = [];
    },
  },
});

export const {
  setSelectedSubcategoryCodes,
  setSelectedLeafCategoryCodes,
  resetSelectedCategories,
  setSelectedLeafCategoryCodesByClickingMain,
  setSelectedLeafCategoryCodesHome,
} = categorySlice.actions;

export default categorySlice.reducer;
