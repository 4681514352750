import api from "./api";

export const login = (data) => {
  return api.post("user/user-login", data);
};
export const resetPassword = (data) => {
  return api.post("user/user-reset-password", data);
};
export const forgotPassword = (data) => {
  return api.post("user/user-forgot-password", data);
};
export const changePassword = (data) => {
  return api.post("user/user-change-password", data);
};
export const impersonateUser = (id) => {
  return api.get(`user/user-impersonate/${id}`);
};
