const {
  CRYPTO_JS_SECRET_KEY,
  ENCRYPT_DECRYPT_IV_KEY,
  REACT_APP_PROJECT_MODE,
} = require("../constants/api.constant");
const CryptoJS = require("crypto-js");

const CRYPTO_SECRET_KEY = CryptoJS.enc.Utf8.parse(CRYPTO_JS_SECRET_KEY);
const ENCRYPT_DECRYPT_SECRET_IV_KEY = CryptoJS.enc.Utf8.parse(
  ENCRYPT_DECRYPT_IV_KEY
);

module.exports = {
  encryption: async (data) => {
    return new Promise((resolve) => {
      try {
        if (
          REACT_APP_PROJECT_MODE === "development" ||
          REACT_APP_PROJECT_MODE === "stage"
        )
          return resolve(data);
        else {
          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            CRYPTO_SECRET_KEY,
            {
              iv: ENCRYPT_DECRYPT_SECRET_IV_KEY,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString();
          return resolve(encryptedData);
        }
      } catch (err) {
        return resolve({
          success: false,
          message: "Encryption Error: " + err.message,
        });
      }
    });
  },

  decryption: async (data) => {
    return new Promise((resolve) => {
      try {
        if (
          REACT_APP_PROJECT_MODE === "development" ||
          REACT_APP_PROJECT_MODE === "stage"
        )
          return resolve(data);
        else {
          if (!data) throw new Error("Missing encrypted data in response body");

          const decryptedString = CryptoJS.AES.decrypt(
            data,
            CRYPTO_SECRET_KEY,
            {
              iv: ENCRYPT_DECRYPT_SECRET_IV_KEY,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString(CryptoJS.enc.Utf8);

          if (!decryptedString)
            throw new Error("Decryption failed: Data is empty or corrupted");

          return resolve(JSON.parse(decryptedString));
        }
      } catch (error) {
        return resolve({
          success: false,
          message: "Decryption Error: " + error.message,
        });
      }
    });
  },
};
