import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import history from "./history";
import Banner from "./components/ui/Banner";
import Header from "./components/ui/Header";
import Navbar from "./components/ui/Navbar";
import Footer from "./components/ui/Footer";
import ScrollToTop from "./components/ui/ScrollToTop";
import { RoleStore } from "./context/roleContext";
import MainContent from "./components/template/MainContent";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RoleStore>
          <BrowserRouter history={history}>
            <ScrollToTop />
            <div className="app-container">
              <div className="fixed top-0 left-0 w-full z-10 bg-white">
                <Banner />
                <Header />
                <Navbar />
              </div>
              <MainContent />
              <Footer />
            </div>
          </BrowserRouter>
          <Toaster position="top-right" reverseOrder={false} />
        </RoleStore>
      </PersistGate>
    </Provider>
  );
};

export default App;
