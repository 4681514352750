import React from "react";
import * as Yup from "yup";
import OtpInput from "otp-input-react";
import { Formik, Field, Form } from "formik";
import Button from "../../../components/ui/Buttons";
import FormContainer from "../../../components/ui/Form/FormContainer";
import FormItem from "../../../components/ui/Form/FormItem";

const validationSchema = Yup.object({
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
});
const OTPInput = ({ closeOtpDialog, openNewPasswordDialog }) => {
  let disableSubmit = false;
  return (
    <div className="text-center text-[#899CBB] text-base font-medium">
      To Continue, please enter 6 digit OTP code sent to your email address
      <Formik
        initialValues={{ otp: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!disableSubmit) {
            closeOtpDialog();
            openNewPasswordDialog();
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, errors, touched }) => {
          return (
            <Form id="otp-form" className="">
              <FormContainer>
                <FormItem
                  className="!w-full !text-center flex items-center justify-center"
                  invalid={errors.otp && touched.otp}
                  errorMessage={errors.otp}
                >
                  <Field name="otp">
                    {({ field }) => (
                      <OtpInput
                        {...field}
                        value={field.value}
                        onChange={field.onChange(field.name)}
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        autoFocus
                        className="opt-container flex gap-3 justify-center items-center mt-5 mb-3"
                        inputStyles={{
                          border: "1px solid #E8ECEC",
                          width: "36px",
                          height: "36px",
                          marginRight: "0px",
                          borderRadius: "6px",
                        }}
                      />
                    )}
                  </Field>
                </FormItem>
              </FormContainer>
              <Button
                block
                loading={isSubmitting}
                type="submit"
                className="text-base w-full rounded-lg font-semibold mt-3"
              >
                {isSubmitting ? "Verifying..." : "Verify"}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OTPInput;
