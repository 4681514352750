import React, { useState } from "react";
import Input from "../ui/Input/Input.js";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

const PasswordInput = (props) => {
  const { onVisibleChange, ...rest } = props;
  const [pwInputType, setPwInputType] = useState("password");

  const onPasswordVisibleClick = (e) => {
    e.preventDefault();
    const nextValue = pwInputType === "password" ? "text" : "password";
    setPwInputType(nextValue);
    onVisibleChange?.(nextValue === "text");
  };

  return (
    <Input
      {...rest}
      type={pwInputType}
      suffix={
        <span
          className="cursor-pointer text-xl"
          onClick={(e) => onPasswordVisibleClick(e)}
        >
          {pwInputType === "password" ? (
            <HiOutlineEyeOff className="text-[#899CBB]" />
          ) : (
            <HiOutlineEye className="text-[#899CBB]" />
          )}
        </span>
      }
    />
  );
};

export default PasswordInput;
