import React from "react";

const Account = () => {
  return (
    <div>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00035 7.73684C8.8939 7.73684 10.4289 6.22875 10.4289 4.36842C10.4289 2.50809 8.8939 1 7.00035 1C5.1068 1 3.57178 2.50809 3.57178 4.36842C3.57178 6.22875 5.1068 7.73684 7.00035 7.73684Z"
          stroke="#1E489E"
          strokeWidth="1.32"
        />
        <path
          d="M7 17C10.3137 17 13 15.4919 13 13.6316C13 11.7713 10.3137 10.2632 7 10.2632C3.68629 10.2632 1 11.7713 1 13.6316C1 15.4919 3.68629 17 7 17Z"
          stroke="#1E489E"
          strokeWidth="1.32"
        />
      </svg>
    </div>
  );
};

export default Account;
