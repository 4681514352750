import React from "react";

const Email = () => {
  return (
    <div>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 14.6H5C2.6 14.6 1 13.4 1 10.6V5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6Z"
          stroke="white"
          strokeWidth="1.32"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4158 4.41504L9.97824 7.84408C9.402 8.28928 8.59784 8.28928 8.0216 7.84408L3.58398 4.41504"
          stroke="white"
          strokeWidth="1.32"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default Email;
