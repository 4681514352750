import React from "react";
import {
  ACCOUNTS_PREFIX_PATH,
  EXCLUSIVE_RANGES_PREFIX_PATH,
  INVOICES_INFO_PREFIX_PATH,
  INVOICES_PREFIX_PATH,
  MY_ORDERS_PREFIX_PATH,
  ORDER_INFO_PREFIX_PATH,
  PANTRY_DETAILS_PREFIX_PATH,
  PANTRY_LIST_PREFIX_PATH,
  RECENT_PURCHASE_PREFIX_PATH,
  REPORTS_PREFIX_PATH,
  SUCCESS_ORDER_PREFIX_PATH,
  ACCESS_DENIED_PATH,
  CART_PREFIX_PATH,
  MONTHLY_SPECIALS_PREFIX_PATH,
} from "../../constants/route.constant";

const appsRoute = [
  {
    key: "accessDenied",
    path: `${ACCESS_DENIED_PATH}`,
    component: React.lazy(() => import("../../views/access-denied")),
    authority: [],
  },
  {
    key: "pantryList",
    path: `${PANTRY_LIST_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/pantryList")),
    authority: [],
  },
  {
    key: "cart",
    path: `${CART_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/cart")),
    authority: [],
  },
  {
    key: "successOrder",
    path: `${SUCCESS_ORDER_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/successOrder")),
    authority: [],
  },
  {
    key: "myOrders",
    path: `${MY_ORDERS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/myOrders")),
    authority: [],
  },
  {
    key: "orderInfo",
    path: `${ORDER_INFO_PREFIX_PATH}/:id`,
    component: React.lazy(() => import("../../views/myOrders/orderInfo")),
    authority: [],
  },
  {
    key: "recentPurchase",
    path: `${RECENT_PURCHASE_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/recentPurchase")),
    authority: [],
  },

  {
    key: "exclusiveRange",
    path: `${EXCLUSIVE_RANGES_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/exclusiveRange")),
    authority: [],
  },
  {
    key: "invoice",
    path: `${INVOICES_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/invoice")),
    authority: [],
  },
  {
    key: "invoiceInfo",
    path: `${INVOICES_INFO_PREFIX_PATH}/:id`,
    component: React.lazy(() => import("../../views/invoice/invoiceInfo")),
    authority: [],
  },
  {
    key: "reports",
    path: `${REPORTS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/reports")),
    authority: [],
  },
  {
    key: "accounts",
    path: `${ACCOUNTS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/accounts")),
    authority: [],
  },

  {
    key: "pantryListInfo",
    path: `${PANTRY_DETAILS_PREFIX_PATH}/:id`,
    component: React.lazy(() =>
      import("../../views/pantryList/pantryListInfo")
    ),
    authority: [],
  },
  {
    key: "monthlySpecials",
    path: `${MONTHLY_SPECIALS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/monthlySpecials")),
    authority: [],
  },
];

export default appsRoute;
