import React from "react";

const SignOut = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.38916 5.2605C6.65021 2.23529 8.20809 1 11.6186 1H11.7281C15.4923 1 16.9997 2.5042 16.9997 6.2605V11.7395C16.9997 15.4958 15.4923 17 11.7281 17H11.6186C8.23335 17 6.67548 15.7815 6.39758 12.8067"
          stroke="currentColor"
          strokeWidth="1.32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5265 8.99158H1.94336"
          stroke="currentColor"
          strokeWidth="1.32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.82105 6.17627L1 8.99139L3.82105 11.8065"
          stroke="currentColor"
          strokeWidth="1.32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SignOut;
