import React from "react";
import Input from "../../../components/ui/Input/index";
import Button from "../../../components/ui/Buttons";
import FormContainer from "../../../components/ui/Form/FormContainer";
import FormItem from "../../../components/ui/Form/FormItem";
import { Field, Form, Formik } from "formik";
import PasswordInput from "../../../components/ui/PasswordInput";
import { IoMailOutline } from "react-icons/io5";
import * as Yup from "yup";
import Password from "../../../components/svgs/Password";
import { NavLink } from "react-router-dom";
import { CONTACT_US_PREFIX_PATH } from "../../../constants/route.constant";
import useAuth from "../../../hooks/useAuth";
import { ErrorToast, SuccessToast } from "../../../utils/commonFunctions";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter email"),
  password: Yup.string().required("Please enter password"),
});
const Login = ({ closeSignInDialog, openForgotPasswordDialog }) => {
  let disableSubmit = false;
  const { signIn } = useAuth();
  const onSignIn = async (values, setSubmitting) => {
    try {
      const loginData = {
        email: values.email?.toLowerCase(),
        password: values.password,
        lastLoginDate: dayjs().format("YYYY-MM-DD"),
      };
      setSubmitting(true);
      try {
        const result = await signIn(loginData);

        if (result?.success) {
          closeSignInDialog();
          SuccessToast("User Logged In Successfully");
        } else {
          ErrorToast(result?.message);
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        setSubmitting(false);
      }
    } catch (e) {
      console.error("e", e);
    }

    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!disableSubmit) {
            onSignIn(values, setSubmitting);
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <FormContainer>
              <FormItem
                label="Email"
                invalid={errors.email && touched.email}
                errorMessage={errors.email}
              >
                <Field
                  type="email"
                  autoComplete="off"
                  name="email"
                  placeholder="Enter your email"
                  component={Input}
                  prefix={<IoMailOutline className="text-[#899CBB]" />}
                />
              </FormItem>
              <FormItem
                label="Password"
                invalid={errors.password && touched.password}
                errorMessage={errors.password}
              >
                <Field
                  autoComplete="off"
                  name="password"
                  placeholder="Enter your password"
                  component={PasswordInput}
                  prefix={<Password />}
                />
              </FormItem>
              <div className="text-primary text-sm font-medium flex items-center justify-end">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    openForgotPasswordDialog();
                  }}
                >
                  Forgot Password?
                </span>
              </div>

              <Button
                block
                loading={isSubmitting}
                type="submit"
                className="text-base w-full rounded-lg font-semibold mt-3"
              >
                {isSubmitting ? "Login..." : "Login"}
              </Button>
              <span className="text-[#899CBB] text-sm font-medium flex items-center justify-center mt-[16px]">
                Don’t have an account?
                <NavLink
                  to={CONTACT_US_PREFIX_PATH}
                  className="ml-2"
                  onClick={() => closeSignInDialog()}
                >
                  <span className="text-primary">Contact Us</span>
                </NavLink>
              </span>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
