import React from "react";
import Input from "../../../components/ui/Input/index";
import Button from "../../../components/ui/Buttons";
import FormContainer from "../../../components/ui/Form/FormContainer";
import FormItem from "../../../components/ui/Form/FormItem";
import { Field, Form, Formik } from "formik";
import { IoMailOutline } from "react-icons/io5";
import * as Yup from "yup";
import { forgotPassword } from "../../../services/authService";
import { ErrorToast, SuccessToast } from "../../../utils/commonFunctions";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter email"),
});
const ForgotPassword = ({ closeForgotPasswordDialog, openOtpDialog }) => {
  let disableSubmit = false;
  const onSendMail = async (values, setSubmitting) => {
    const { email } = values;
    setSubmitting(true);
    // console.log("formData", formData);
    try {
      const resp = await forgotPassword({ email: email.toLowerCase() });
      if (resp?.success) {
        SuccessToast(
          "We have sent a password recovery instruction to your email"
        );
        closeForgotPasswordDialog();
      } else {
        ErrorToast(resp?.message);
        setSubmitting(false);
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.error?.message ||
        err?.response?.data?.error ||
        err?.response?.data?.message ||
        "Something went wrong!";
      ErrorToast(errorMessage);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!disableSubmit) {
            onSendMail(values, setSubmitting);

            // openOtpDialog();
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <FormContainer>
              <FormItem
                label="Email"
                invalid={errors.email && touched.email}
                errorMessage={errors.email}
              >
                <Field
                  type="email"
                  autoComplete="off"
                  name="email"
                  placeholder="Enter your email"
                  component={Input}
                  prefix={<IoMailOutline className="text-[#899CBB]" />}
                />
              </FormItem>

              <Button
                block
                loading={isSubmitting}
                type="submit"
                className="text-base w-full rounded-lg font-semibold mt-3"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
