import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  authRoutes,
  protectedRoutes,
  publicRoutes,
} from "../configs/routes.config";
import AuthorityGuard from "../route/authorityGuard";
import AppRoute from "../route/appRoute";
import ProtectedRoute from "../route/protectedRoute";
import AuthRoute from "../route/authRoute";
import PublicRoute from "../route/publicRoute";
import Spinner from "../components/ui/Spinner";

const Views = () => {
  // check user authority to check route access
  const userAuthority = useSelector((state) => state.auth.user.authority);
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-[300px]">
          <Spinner loading={true} size={32} />
        </div>
      }
    >
      <Routes>
        {/* map public routes which are access when user is authenticated or not authenticated */}
        <Route path="/" element={<PublicRoute />}>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))}
        </Route>

        {/* map auth routes which are only access when user is not authenticated, if user authentic then it will redirect to authenticated entryPath */}
        <Route path="/" element={<AuthRoute />}>
          {authRoutes?.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))}
        </Route>
        {/* map private routes which are only access when user is authenticated, 
        if user is not authentic then it will redirect to unauthenticated entryPath */}
        <Route path="/" element={<ProtectedRoute />}>
          {protectedRoutes.map((route, index) => (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AuthorityGuard
                  userAuthority={userAuthority}
                  authority={route.authority}
                >
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                </AuthorityGuard>
              }
            />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default Views;
