import React from "react";
import * as Yup from "yup";
import Password from "../../../components/svgs/Password";
import PasswordInput from "../../../components/ui/PasswordInput";
import { Field, Form, Formik } from "formik";
import FormContainer from "../../../components/ui/Form/FormContainer";
import FormItem from "../../../components/ui/Form/FormItem";
import Button from "../../../components/ui/Buttons";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[\d!@#$%^&*(),.?":{}|<>]).{8,}$/,
      "Password must be 8+ chars, 1 number or 1 special char."
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const NewPassword = ({ closeNewPasswordDialog }) => {
  let disableSubmit = false;
  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!disableSubmit) {
            // onSubmit(values, setSubmitting);
            closeNewPasswordDialog();
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <FormContainer>
              <>
                <div className="flex flex-col gap-3 mt-3">
                  <FormItem
                    label="Password"
                    invalid={errors.password && touched.password}
                    errorMessage={errors.password}
                  >
                    <Field
                      autoComplete="off"
                      name="password"
                      placeholder="Password"
                      component={PasswordInput}
                      prefix={<Password />}
                    />
                  </FormItem>
                  <FormItem
                    label="Confirm Password"
                    invalid={errors.confirmPassword && touched.confirmPassword}
                    errorMessage={errors.confirmPassword}
                  >
                    <Field
                      autoComplete="off"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      component={PasswordInput}
                      prefix={<Password />}
                    />
                  </FormItem>
                </div>
                <div>
                  <Button
                    block
                    loading={isSubmitting}
                    type="submit"
                    className="text-sm w-full rounded-lg font-semibold mt-3"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </div>
              </>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPassword;
