import api from "./api";

export function getPrivacyPolicy() {
  return api.get("user/privacy-policy");
}
export function getTermsAndConditionPolicy() {
  return api.get("user/terms-condition-list");
}
export function getTermsOfUsePolicy() {
  return api.get("user/term-of-use");
}
export function getDeliveryInfo() {
  return api.get("user/delivery-information");
}
export function getReturnRefundPolicy() {
  return api.get("user/refund-return-policy");
}
export function getAboutUsPageData() {
  return api.get("user/about-us");
}
export function getFAQData() {
  return api.get("user/faqs");
}
export function addContactUsData(data) {
  return api.post("user/contact-us", data);
}
export function getAllProducts(perPage, pageNo, data) {
  return api.post(`user/product?perPage=${perPage}&pageNo=${pageNo}`, data);
}
export function getAllHeroBanners() {
  return api.get("user/banner-list?bannerType=hero");
}
export function getAllExclusiveBanners() {
  return api.get("user/banner-list?bannerType=exclusive");
}
export function getAllMonthlyBanners() {
  return api.get("user/banner-list?bannerType=monthly");
}
export function getAllPromoBanners() {
  return api.get("user/banner-list?bannerType=promo");
}
export function getAllRecentBanners() {
  return api.get("user/banner-list?bannerType=recent");
}
export function getAddressDetails(id) {
  return api.get(`user/customer-delivery-address/${id}`);
}
export function getAdvertisementDetails() {
  return api.get("user/all-advertisement");
}
export function getExclusiveRangeData(perPage, pageNo, data) {
  return api.post(
    `user/all-product?perPage=${perPage}&pageNo=${pageNo}&excRngProduct=true`,
    data
  );
}
export function getMonthlySpecialData(perPage, pageNo, data) {
  return api.post(
    `user/all-product?perPage=${perPage}&pageNo=${pageNo}&MonthlySpecial=true`,
    data
  );
}
export function fetchAllFooterSection() {
  return api.get(`user/footer-description`);
}
export function fetchAllQRCodes() {
  return api.get(`user/all-qr-code`);
}
export function getUserDetails(id) {
  return api.get(`user/customer/${id}`);
}
export function getSocialMedia() {
  return api.get(`user/social-media`);
}
