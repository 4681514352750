import api from "./api";

export function createPantryList(data) {
  return api.post("user/pantry", data);
}
export function getAllPantryList(id, perPage, pageNo, data) {
  return api.post(
    `user/all-pantry/${id}?perPage=${perPage}&pageNo=${pageNo}`,
    data
  );
}
export function updatePantryList(id, data) {
  return api.patch(`user/pantry/${id}`, data);
}
export function deletePantryList(id) {
  return api.delete(`user/pantry/${id}`);
}
export function getPantryDetailsById(data) {
  return api.post(`user/all-pantry-product`, data);
}
export function getAllPantryListWithoutPagination(id, data) {
  return api.post(`user/all-pantry/${id}`, data);
}
export function addProductToPantry(data) {
  return api.post("user/pantry-product", data);
}
export function getProductByProductId(productId) {
  return api.get(`user/one-product/${productId}`);
}
export function deletePantryProductById(data) {
  return api.delete(`user/pantry-product`, data);
}
export function updatePantryProductQuantity(id, data) {
  return api.patch(`user/pantry-product/${id}`, data);
}
export function getAllOrders(perPage, pageNo, data) {
  return api.post(
    `user/all-user-order?perPage=${perPage}&pageNo=${pageNo}`,
    data
  );
}
export function getOrderDetails(orderId) {
  return api.get(`user/user-order/${orderId}`);
}
export function getAllInvoices(perPage, pageNo, data) {
  return api.post(
    `user/user-order-invoice?perPage=${perPage}&pageNo=${pageNo}`,
    data
  );
}
export function getOrderInvoiceDetails(userOrderInvoiceId) {
  return api.get(`user/user-order-invoice/${userOrderInvoiceId}`);
}
