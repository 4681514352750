import React from "react";

const Facebook = () => {
  return (
    <div>
      <svg
        width="7"
        height="16"
        viewBox="0 0 7 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.06452 15.2258C2.06452 15.6534 2.41115 16 2.83871 16C3.26627 16 3.6129 15.6534 3.6129 15.2258H2.06452ZM3.6129 6.96774C3.6129 6.54018 3.26627 6.19355 2.83871 6.19355C2.41115 6.19355 2.06452 6.54018 2.06452 6.96774H3.6129ZM5.93548 1.54839C6.36305 1.54839 6.70968 1.20177 6.70968 0.774194C6.70968 0.346622 6.36305 0 5.93548 0V1.54839ZM2.06452 6.96774C2.06452 7.3953 2.41115 7.74194 2.83871 7.74194C3.26627 7.74194 3.6129 7.3953 3.6129 6.96774H2.06452ZM2.83871 6.19355C2.41115 6.19355 2.06452 6.54018 2.06452 6.96774C2.06452 7.3953 2.41115 7.74194 2.83871 7.74194V6.19355ZM4.90323 7.74194C5.33079 7.74194 5.67742 7.3953 5.67742 6.96774C5.67742 6.54018 5.33079 6.19355 4.90323 6.19355V7.74194ZM2.83871 7.74194C3.26627 7.74194 3.6129 7.3953 3.6129 6.96774C3.6129 6.54018 3.26627 6.19355 2.83871 6.19355V7.74194ZM0.774194 6.19355C0.346632 6.19355 0 6.54018 0 6.96774C0 7.3953 0.346632 7.74194 0.774194 7.74194V6.19355ZM3.6129 15.2258V6.96774H2.06452V15.2258H3.6129ZM5.93548 0C3.79757 0 2.06452 1.73309 2.06452 3.87097H3.6129C3.6129 2.58824 4.6528 1.54839 5.93548 1.54839V0ZM2.06452 3.87097V6.96774H3.6129V3.87097H2.06452ZM2.83871 7.74194H4.90323V6.19355H2.83871V7.74194ZM2.83871 6.19355H0.774194V7.74194H2.83871V6.19355Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Facebook;
