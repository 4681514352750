import axios from "axios";
import { BACKEND_SERVER_URL } from "../constants/api.constant";
import { PERSIST_STORE_NAME } from "../constants/app.constant";
import deepParseJson from "../utils/deepParseJson";
import { encryption, decryption } from "../hooks/useCryptograpy";

// const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
// const persistData = deepParseJson(rawPersistData);
// const accessToken = persistData?.auth?.session?.token;
const api = axios.create({
  baseURL: `${BACKEND_SERVER_URL}`,
  headers: {
    // Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add an Axios interceptor to dynamically set the Authorization header before each request
api.interceptors.request.use(
  async function (config) {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);
    const accessToken = persistData?.auth?.session?.token;
    if (accessToken) {
      config.headers.Authorization = `${accessToken}`;
    }
    const encryptedData = await encryption(config.data);
    config.data = { encryptedData };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = await decryption(response.data);
    return result;
  },
  async function (error) {
    // Do something with response error
    const result = await decryption(error.response.data);
    // console.log("result", result);
    return Promise.resolve(result);
  }
);

export default api;
