import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";
import BrowseDropdown from "../BrowseDropdown";
import {
  BROWSE_CATEGORY_PAGE_PREFIX_PATH,
  EXCLUSIVE_RANGES_PREFIX_PATH,
  MONTHLY_SPECIALS_PREFIX_PATH,
  MY_ORDERS_PREFIX_PATH,
  PANTRY_LIST_PREFIX_PATH,
  RECENT_PURCHASE_PREFIX_PATH,
} from "../../../constants/route.constant";

const MENU_ITEMS = [
  { path: EXCLUSIVE_RANGES_PREFIX_PATH, label: "Exclusive Range" },
  { path: PANTRY_LIST_PREFIX_PATH, label: "Pantry Lists" },
  { path: MY_ORDERS_PREFIX_PATH, label: "My Orders" },
  { path: RECENT_PURCHASE_PREFIX_PATH, label: "Recent Purchases" },
  { path: MONTHLY_SPECIALS_PREFIX_PATH, label: "Monthly Specials" },
];

const Navbar = () => {
  const dropdownRef = useRef(null);
  const user = useSelector((state) => state?.auth?.user?.id);
  const [isHovering, setIsHovering] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Select Menu");

  const handleMenuClick = () => setIsMenuOpen((prev) => !prev);
  const handleMenuItemClick = (menuName) => {
    setSelectedMenu(menuName);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsHovering(false);
      }
    };

    if (isHovering) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHovering]);

  return (
    <div>
      <div className="hidden lg:block bg-primary mt-[20px]">
        <div className="container mx-auto min-h-[40px] grid xl:grid-cols-6 grid-flow-col auto-cols-max items-center text-xs font-semibold">
          <div
            onClick={() => setIsHovering(true)}
            // onMouseEnter={() => setIsHovering(true)}
            // onMouseLeave={() => setIsHovering(false)}
            className={`flex items-center text-base h-full px-3 cursor-pointer ${
              isHovering ? "bg-yellow text-black" : "text-white"
            }`}
          >
            Browse Categories
          </div>
          {user &&
            MENU_ITEMS.map(({ path, label }) => (
              <NavLink
                key={path}
                to={path}
                className={({ isActive }) =>
                  `flex items-center text-base h-full px-3 ${
                    isActive ? "bg-yellow text-black" : "text-white"
                  }`
                }
              >
                {label}
              </NavLink>
            ))}
        </div>
      </div>

      <div className="block lg:hidden min-h-[40px] bg-primary mt-[20px]">
        <div
          className="container mx-auto text-white text-sm font-bold flex items-center justify-between pt-2 cursor-pointer"
          onClick={handleMenuClick}
        >
          {selectedMenu}
          <MdOutlineKeyboardArrowDown
            size={24}
            className={`transition-transform duration-300 ${
              isMenuOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>

        <div
          className={`transition-all duration-500 ease-in-out overflow-hidden ${
            isMenuOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="container mx-auto bg-primary text-white text-xs font-bold shadow-lg pb-2">
            <NavLink
              to={BROWSE_CATEGORY_PAGE_PREFIX_PATH}
              onClick={() => handleMenuItemClick("Browse Categories")}
              className="block px-2 py-2 hover:bg-yellow rounded"
            >
              Browse Categories
            </NavLink>
            {user &&
              MENU_ITEMS.map(({ path, label }) => (
                <NavLink
                  key={path}
                  to={path}
                  onClick={() => handleMenuItemClick(label)}
                  className="block px-2 py-2 hover:bg-yellow rounded"
                >
                  {label}
                </NavLink>
              ))}
          </div>
        </div>
      </div>

      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isHovering && (
          <div ref={dropdownRef} className="container mx-auto">
            <BrowseDropdown setIsHovering={setIsHovering} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
