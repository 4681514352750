import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../services/authService";
import { onSignInSuccess, onSignOutSuccess } from "../store/auth/sessionSlice";
import { setUser, initialState } from "../store/auth/userSlice";
import appConfig from "../configs/app.config";
function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async (payload) => {
    try {
      const resp = await login(payload);
      // console.log("resp", resp);
      if (resp?.success) {
        const token = resp?.data?.token;
        dispatch(onSignInSuccess(token));
        const userData = resp?.data;
        dispatch(
          setUser({
            id: userData.userId ? userData?.userId : "1",
            authority: [],
            email: userData?.userEmail ? userData.userEmail : "test@gmail.com",
            customerCode: userData?.customerCode ? userData?.customerCode : "",
            userName: userData?.customerName
              ? userData.customerName
              : "Test User",
            orderNoRequired: userData?.orderNoRequired,
          })
        );

        navigate(appConfig.authenticatedEntryPath);

        return {
          success: true,
          message: "Access Granted",
        };
      } else {
        return {
          success: false,
          message: resp?.message ? resp.message : "",
        };
      }
    } catch (err) {
      console.error("err", err);
      return {
        status: "failed",
        message: err?.response?.data?.message || err.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };

  return {
    authenticated: token && signedIn === true && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
