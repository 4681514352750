import React, { useEffect, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BROWSE_CATEGORIES_PREFIX_PATH } from "../../../constants/route.constant";
import { getAllCategoriesWithCodes } from "../../../services/categoryService";
import Spinner from "../Spinner";
import {
  resetSelectedCategories,
  setSelectedLeafCategoryCodes,
  setSelectedLeafCategoryCodesByClickingMain,
  setSelectedSubcategoryCodes,
} from "../../../store/categories/categorySlice";

const BrowseDropdown = ({ setIsHovering }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [categoriesApiData, setCategoriesApiData] = useState([]);
  const [dataApiData, setDataApiData] = useState({});

  const fetchCategoriesApiData = async () => {
    try {
      setLoading(true);
      const resp = await getAllCategoriesWithCodes();
      if (resp?.success) {
        const categoryNames = resp?.data?.map((item) => item?.name) || [];
        setCategoriesApiData(categoryNames);

        const formattedData = Array.isArray(resp?.data)
          ? resp.data.reduce((acc, category) => {
              acc[category.name] = {
                categoryCode: category.categoryCode || null,
                subcategories: category.subCategories
                  ? category.subCategories.reduce((subAcc, subcategory) => {
                      subAcc[subcategory.name] = {
                        subCategoryCode: subcategory.categoryCode || null,
                        leafCategories: subcategory.leafCategories || [],
                      };
                      return subAcc;
                    }, {})
                  : null,
              };
              return acc;
            }, {})
          : {};

        setDataApiData(formattedData);
      }
    } catch (err) {
      console.error("Error fetching categories data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetSelectedCategories());
    fetchCategoriesApiData(); // eslint-disable-next-line
  }, []);

  return (
    <div className="grid grid-cols-5 text-base font-semibold mb-10 absolute">
      <div className="bg-[#FFFCF0] h-[580px] overflow-y-auto scrollbar-hide col-span-1">
        {loading ? (
          <div className="flex items-center justify-center h-[730px] min-w-[276px]">
            <Spinner loading={true} size={32} />
          </div>
        ) : (
          <>
            {categoriesApiData?.map((category) => (
              <div
                key={category}
                className={`flex gap-2 items-center justify-between cursor-pointer py-2 px-4 transition-colors duration-200 ${
                  hoveredCategory === category ? "bg-primary text-white" : ""
                }`}
                onMouseEnter={() => setHoveredCategory(category)}
                onClick={() => {
                  const selectedSubcategories =
                    dataApiData[category]?.subcategories || {};

                  const subCategoryCodes = Object.values(selectedSubcategories)
                    ?.map((sub) => sub.subCategoryCode)
                    ?.filter(Boolean);

                  const leafCategoryCodes = Object.values(selectedSubcategories)
                    ?.flatMap((sub) => sub.leafCategories || [])
                    ?.map((leaf) => leaf.categoryCode)
                    ?.filter(Boolean);

                  const allSubCategoryCodes = subCategoryCodes?.flat();
                  const allLeafCategoryCodes = leafCategoryCodes?.flat();

                  dispatch(setSelectedSubcategoryCodes(allSubCategoryCodes));
                  dispatch(
                    setSelectedLeafCategoryCodesByClickingMain(
                      allLeafCategoryCodes
                    )
                  );

                  navigate(`${BROWSE_CATEGORIES_PREFIX_PATH}`, {
                    state: {
                      categoryName: category,
                      subcategoryName: selectedSubcategories,
                    },
                  });
                  setIsHovering(false);
                }}
              >
                <div className="text-base font-semibold">{category}</div>
                <MdArrowForwardIos />
              </div>
            ))}
          </>
        )}
      </div>

      {hoveredCategory && (
        <div
          className="col-span-4 px-4 bg-white"
          onMouseEnter={() => setHoveredCategory(hoveredCategory)}
          onMouseLeave={() => setHoveredCategory(null)}
        >
          <div className="columns-4 gap-4 h-[550px] overflow-y-auto scrollbar-hide mt-5">
            {Object.entries(
              dataApiData[hoveredCategory]?.subcategories || {}
            )?.map(([subcategory, items]) => (
              <div
                key={subcategory}
                className="break-inside-avoid mb-4"
                onClick={() => {
                  if (items?.leafCategories?.length > 0)
                    dispatch(
                      setSelectedSubcategoryCodes(
                        items?.leafCategories?.map((leaf) => leaf?.categoryCode)
                      )
                    );
                  else if (items?.subCategoryCode)
                    dispatch(
                      setSelectedSubcategoryCodes([items.subCategoryCode])
                    );

                  navigate(`${BROWSE_CATEGORIES_PREFIX_PATH}`, {
                    state: {
                      subcategoryName:
                        dataApiData[hoveredCategory]?.subcategories,
                      categoryName: hoveredCategory,
                      currentSubcategoryName: subcategory,
                    },
                  });
                  setIsHovering(false);
                }}
              >
                <div className="text-base font-semibold mb-2 cursor-pointer hover:bg-primary hover:text-white transition-colors duration-200 group rounded-s-[6px]">
                  <div className="flex items-center gap-[6px] md:gap-[12px]">
                    <div className="w-[12px] md:w-[16px] h-[20px] md:h-[32px] bg-yellow rounded-[4px]"></div>
                    <span className="text-primary text-base md:text-base font-bold group-hover:text-white">
                      {subcategory}
                    </span>
                  </div>
                </div>

                <ul>
                  {items?.leafCategories?.map((leaf) => (
                    <li
                      key={leaf.categoryCode || leaf.name}
                      className="text-sm py-2 font-semibold cursor-pointer hover:bg-primary hover:text-white transition-colors duration-200 px-7"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          setSelectedLeafCategoryCodes(leaf.categoryCode)
                        );

                        navigate(`${BROWSE_CATEGORIES_PREFIX_PATH}`, {
                          state: {
                            subcategoryName:
                              dataApiData[hoveredCategory]?.subcategories,
                            categoryName: hoveredCategory,
                            currentSubcategoryName: subcategory,
                          },
                        });
                        setIsHovering(false);
                      }}
                    >
                      {leaf.name}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BrowseDropdown;
