export const ROOT = "/";
export const ACCESS_DENIED_PATH = "/access-denied";
export const HOME_PREFIX_PATH = "/home";
export const BROWSE_PREFIX_PATH = "/browse";
export const BROWSE_CATEGORIES_PREFIX_PATH = "/browse-categories";
export const PANTRY_LIST_PREFIX_PATH = "/pantry-list";
export const QUOTE_PREFIX_PATH = "/quote";
export const CART_PREFIX_PATH = "/cart";
export const SUCCESS_ORDER_PREFIX_PATH = "/success-order";
export const MY_ORDERS_PREFIX_PATH = "/my-orders";
export const ORDER_INFO_PREFIX_PATH = "/order-info";
export const RECENT_PURCHASE_PREFIX_PATH = "/recent-purchase";
export const MONTHLY_SPECIALS_PREFIX_PATH = "/monthly-specials";
export const ABOUT_US_PREFIX_PATH = "/about-us";
export const EXCLUSIVE_RANGES_PREFIX_PATH = "/exclusive-range";
export const INVOICES_PREFIX_PATH = "/invoices";
export const INVOICES_INFO_PREFIX_PATH = "/invoice-info";
export const REPORTS_PREFIX_PATH = "/reports";
export const ACCOUNTS_PREFIX_PATH = "/account";
export const CONTACT_US_PREFIX_PATH = "/contact-us";
export const FAQS_PREFIX_PATH = "/faqs";
export const PRIVACY_POLICY_PREFIX_PATH = "/privacy-policy";
export const TERMS_AND_CONDITIONS_PREFIX_PATH = "/terms-and-conditions";
export const RETURN_POLICY_PREFIX_PATH = "/return-policy";
export const DELIVERY_INFORMATION_PREFIX_PATH = "/delivery-information";
export const TERMS_OF_USE_PREFIX_PATH = "/terms-of-use";
export const LOGIN_PREFIX_PATH = "/login";
export const PRODUCT_DETAILS_PREFIX_PATH = "/product-details";
export const PANTRY_DETAILS_PREFIX_PATH = "/pantry-details";
export const BROWSE_CATEGORY_PAGE_PREFIX_PATH = "/browse-category-page";
export const PRODUCTS_PREFIX_PATH = "/products";
