import React from "react";

const LinkedIn = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0003 7.95831C10.7645 7.17747 11.7595 6.66664 12.917 6.66664C14.1326 6.66664 15.2984 7.14953 16.1579 8.00907C17.0174 8.86861 17.5003 10.0344 17.5003 11.25V17.5H15.8337V11.25C15.8337 10.4764 15.5264 9.73456 14.9794 9.18758C14.4324 8.6406 13.6905 8.33331 12.917 8.33331C12.1434 8.33331 11.4016 8.6406 10.8546 9.18758C10.3076 9.73456 10.0003 10.4764 10.0003 11.25V17.5H8.33366V7.08331H10.0003V7.95831ZM4.16699 5.41664C3.83547 5.41664 3.51753 5.28495 3.28311 5.05052C3.04869 4.8161 2.91699 4.49816 2.91699 4.16664C2.91699 3.83512 3.04869 3.51718 3.28311 3.28276C3.51753 3.04834 3.83547 2.91664 4.16699 2.91664C4.49851 2.91664 4.81646 3.04834 5.05088 3.28276C5.2853 3.51718 5.41699 3.83512 5.41699 4.16664C5.41699 4.49816 5.2853 4.8161 5.05088 5.05052C4.81646 5.28495 4.49851 5.41664 4.16699 5.41664ZM3.33366 7.08331H5.00033V17.5H3.33366V7.08331Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default LinkedIn;
