import React from "react";
const authRoute = [
  {
    key: "resetPassword",
    path: `/reset-password`,
    component: React.lazy(() => import("../../views/auth/setNewPassword")),
    authority: [],
  },
];

export default authRoute;
