import api from "./api";
export function getCartData(data) {
  return api.post("user/all-user-cart", data);
}
export function addToCartApi(data) {
  return api.post("user/user-cart", data);
}
export function updateCartData(id, data) {
  return api.patch(`user/user-cart/${id}`, data);
}
export function deleteFromCart(data) {
  return api.delete("user/user-cart", data);
}
