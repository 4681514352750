import { useLocation } from "react-router-dom";
import Views from "../../views";

const MainContent = () => {
  const location = useLocation();

  const excludedPaths = [
    "/privacy-policy",
    "/terms-and-conditions",
    "/return-policy",
    "/delivery-information",
    "/terms-of-use",
  ];

  // Check if the current path is NOT in the excluded list
  const shouldApplyMinHScreen = !excludedPaths.includes(location.pathname);

  return (
    <div
      className={`main-content pt-[220px] md:pt-[180px] ${
        shouldApplyMinHScreen ? "min-h-screen" : ""
      }`}
    >
      <Views />
    </div>
  );
};

export default MainContent;
