import React from "react";

const CartIcon = ({ color }) => {
  return (
    <div className="cursor-pointer">
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L1.35008 1.11773C3.09454 1.70442 3.96676 1.99775 4.46565 2.69612C4.96455 3.39448 4.96455 4.3221 4.96455 6.17736V9.66662C4.96455 13.4378 4.96455 15.3234 6.12574 16.495C7.28693 17.6666 9.15583 17.6666 12.8936 17.6666H23.4658"
          stroke={color}
          strokeWidth="1.32"
          strokeLinecap="round"
        />
        <path
          d="M8.26841 21C9.36319 21 10.2507 21.8955 10.2507 23C10.2507 24.1045 9.36319 25 8.26841 25C7.17362 25 6.28613 24.1045 6.28613 23C6.28613 21.8955 7.17362 21 8.26841 21Z"
          stroke={color}
          strokeWidth="1.32"
        />
        <path
          d="M20.162 21C21.2567 21 22.1442 21.8953 22.1442 23C22.1442 24.1045 21.2567 25 20.162 25C19.0672 25 18.1797 24.1045 18.1797 23C18.1797 21.8953 19.0672 21 20.162 21Z"
          stroke={color}
          strokeWidth="1.32"
        />
        <path
          d="M4.96484 5H20.0967C22.8124 5 24.1702 5 24.7578 5.89901C25.3454 6.79803 24.8104 8.05724 23.7407 10.5757L23.1743 11.909C22.6749 13.0848 22.4251 13.6726 21.9286 14.003C21.4321 14.3333 20.7982 14.3333 19.5303 14.3333H4.96484"
          stroke={color}
          strokeWidth="1.32"
        />
      </svg>
    </div>
  );
};

export default CartIcon;
