import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { getAdvertisementDetails } from "../../../services/commonService";

const Banner = () => {
  const [data, setData] = useState([]);

  const fetchBannerData = async () => {
    try {
      const resp = await getAdvertisementDetails();
      if (resp?.success) {
        setData([]);
        setTimeout(() => setData(resp?.data), 10);
      }
    } catch (err) {
      console.error("Error fetching banner data", err);
    }
  };

  useEffect(() => {
    fetchBannerData();
  }, []);

  return (
    <div>
      {data?.length > 0 && (
        <div className="w-full h-[40px] bg-[#FFCD03] z-50">
          <div className="text-base font-semibold pt-2">
            <Marquee key={data.length} gradient={false} speed={80}>
              {data?.map((item) => (
                <div key={item.id} className="px-20">
                  {item.description}
                </div>
              ))}
              <div className="px-20"></div>
            </Marquee>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
