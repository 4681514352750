import React from "react";
import {
  ABOUT_US_PREFIX_PATH,
  BROWSE_CATEGORIES_PREFIX_PATH,
  BROWSE_CATEGORY_PAGE_PREFIX_PATH,
  BROWSE_PREFIX_PATH,
  CONTACT_US_PREFIX_PATH,
  DELIVERY_INFORMATION_PREFIX_PATH,
  EXCLUSIVE_RANGES_PREFIX_PATH,
  FAQS_PREFIX_PATH,
  LOGIN_PREFIX_PATH,
  PRIVACY_POLICY_PREFIX_PATH,
  PRODUCT_DETAILS_PREFIX_PATH,
  PRODUCTS_PREFIX_PATH,
  QUOTE_PREFIX_PATH,
  RETURN_POLICY_PREFIX_PATH,
  ROOT,
  TERMS_AND_CONDITIONS_PREFIX_PATH,
  TERMS_OF_USE_PREFIX_PATH,
} from "../../constants/route.constant";

const publicRoute = [
  {
    key: "home",
    path: `${ROOT}`,
    component: React.lazy(() => import("../../views/home")),
    authority: [],
  },
  {
    key: "browse",
    path: `${BROWSE_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/browse")),
    authority: [],
  },
  {
    key: "browseCategories",
    path: `${BROWSE_CATEGORIES_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/browseCategories")),
    authority: [],
  },
  {
    key: "browseCategoriesMobile",
    path: `${BROWSE_CATEGORY_PAGE_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/browseCategoriesMobile")),
    authority: [],
  },

  {
    key: "exclusiveRange",
    path: `${EXCLUSIVE_RANGES_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/exclusiveRange")),
    authority: [],
  },
  {
    key: "aboutUs",
    path: `${ABOUT_US_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/aboutUs")),
    authority: [],
  },

  {
    key: "contactUs",
    path: `${CONTACT_US_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/contactUs")),
    authority: [],
  },
  {
    key: "faqs",
    path: `${FAQS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/faqs")),
    authority: [],
  },
  {
    key: "privacyPolicy",
    path: `${PRIVACY_POLICY_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/privacyPolicy")),
    authority: [],
  },
  {
    key: "termsAndConditions",
    path: `${TERMS_AND_CONDITIONS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/termsAndConditions")),
    authority: [],
  },
  {
    key: "returnPolicy",
    path: `${RETURN_POLICY_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/returnPolicy")),
    authority: [],
  },
  {
    key: "deliveryInformation",
    path: `${DELIVERY_INFORMATION_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/deliveryInformation")),
    authority: [],
  },
  {
    key: "termsOfUse",
    path: `${TERMS_OF_USE_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/termsOfUse")),
    authority: [],
  },
  {
    key: "login",
    path: `${LOGIN_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/auth/login")),
    authority: [],
  },
  {
    key: "productDetails",
    path: `${PRODUCT_DETAILS_PREFIX_PATH}/:id`,
    component: React.lazy(() => import("../../views/productDetails")),
    authority: [],
  },
  {
    key: "products",
    path: `${PRODUCTS_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/products")),
    authority: [],
  },
  {
    key: "quote",
    path: `${QUOTE_PREFIX_PATH}`,
    component: React.lazy(() => import("../../views/quote")),
    authority: [],
  },
];

export default publicRoute;
