import toast from "react-hot-toast";

export const SuccessToast = (message) =>
  toast.success(message, {
    position: "top-right",
    style: {
      padding: "12px",
      background: "#1E489E",
      color: "#fff",
      fontSize: "14px",
    },
  });

export const ErrorToast = (message) =>
  toast.error(message, {
    position: "top-right",
    style: {
      padding: "12px",
      background: "#1E489E",
      color: "#fff",
      fontSize: "14px",
    },
  });

export const formatDate = (date) => {
  const options = { month: "numeric", day: "numeric", year: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

export function formatDateString(isoString) {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const amPm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;
  return `${day}/${month}/${year}, ${hours}:${minutes} ${amPm}`;
}

export function capitalizeFirstLetter(word) {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const calculateTotals = (products) => {
  const totalAmount = products?.reduce((sum, item) => {
    const price = item?.userCartDetails?.price?.NewPrice
      ? item?.userCartDetails?.uomMultiplier
        ? item?.userCartDetails?.price?.NewPrice *
          item?.userCartDetails?.uomMultiplier
        : item?.userCartDetails?.price?.NewPrice
      : item?.userCartDetails?.uomMultiplier
      ? item?.userCartDetails?.price * item?.userCartDetails?.uomMultiplier
      : item?.userCartDetails?.price;

    // item?.userCartDetails?.price?.NewPrice ?? item?.userCartDetails?.price;
    return sum + price * item?.quantity;
  }, 0);

  const gstAmount = totalAmount * 0.15;
  const totalWithGst = totalAmount + gstAmount;

  return {
    totalAmount: totalAmount.toFixed(2),
    gstAmount: gstAmount.toFixed(2),
    totalWithGst: totalWithGst.toFixed(2),
  };
};

export const formatDeliveryDate = (isoDate, offsetHours = 6) => {
  const date = new Date(isoDate);
  date.setHours(date.getHours() + offsetHours);
  return date.toISOString().split("T")[0];
};

export function formatInvoiceDate(dateString, timeString) {
  const date = new Date(dateString);
  const timeParts = timeString?.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  date.setHours(hours);
  date.setMinutes(minutes);
  const formattedDate = date.toLocaleDateString("en-GB");
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
}
export const formatDatePayload = (date) => {
  if (!date) return null;

  const localDate = new Date(date);
  localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset()); // Adjust for timezone
  return localDate.toISOString().split("T")[0]; // Extracts YYYY-MM-DD
};

export const calculateTotalsForPantry = (products) => {
  const totalAmount = products?.reduce((sum, item) => {
    // Determine the correct price, falling back to the standard price if no special price exists
    const price = item?.specialPrice?.NewPrice
      ? item?.uomMultiplier
        ? item?.specialPrice?.NewPrice * item?.uomMultiplier
        : item?.specialPrice?.NewPrice
      : item?.uomMultiplier
      ? item?.price * item?.uomMultiplier
      : item?.price;

    // const price = item?.specialPrice?.NewPrice ?? item?.price;

    return sum + price * item?.quantity;
  }, 0);

  const gstAmount = totalAmount * 0.15;
  const totalWithGst = totalAmount + gstAmount;

  return {
    totalAmount: totalAmount.toFixed(2),
    gstAmount: gstAmount.toFixed(2),
    totalWithGst: totalWithGst.toFixed(2),
  };
};
